<template>
  <div
    v-if="isShowModal"
    class="new-child"
  >
    <span class="new-child__close" @click="closeModal"></span>
    <h2 class="new-child__title">Adding a {{ camperStudent }}</h2>

    <div
      v-for="field in formFields"
      :key="field.id"
    >
      <!--Avatar-->
      <div v-if="field.key === 'avatar'" class="new-child__avatar">

        <div
          v-if="formValues[field.key]"
          class="new-child__avatar-img"
          :class="{'is-invalid': $v.formValues[field.key] && $v.formValues[field.key].$dirty && !$v.formValues[field.key].required}"
        >
          <img
          :src="avatarPreview"
          alt="Photo"
          >
        </div>
        <div
          v-else
          class="new-child__avatar-img"
          :class="{'is-invalid': $v.formValues[field.key] && $v.formValues[field.key].$dirty && !$v.formValues[field.key].required}"
          @click="selectFile"
        >
        </div>

        <div
          class="new-child__avatar-btn"
          @click="selectFile"
        >
          <CameraSVG /><span>Select photo {{field.is_required ? '*' : ''}}</span>
        </div>
        <input
          type="file"
          ref="refInputEl"
          accept=".jpg, .png, .gif, .jpeg"
          v-show="true"
          @input="handleFileUpload"
        >
      </div>

      <!--Nickname-->
      <label
        v-if="['nickname', 'first_name', 'last_name'].indexOf(field.key) !== -1"
        class="form__row"
      >
        <span class="form__row-label">{{ field.label }}</span>
        <input
          v-model="formValues[field.key]"
          type="text"
          class="input"
          :class="{'is-invalid': $v.formValues[field.key] && $v.formValues[field.key].$dirty && !$v.formValues[field.key].required}"
          @input="capitalizeFirstLetter($event.target.value, field.key)"
        />
      </label>

      <!--DoB-->
      <label
        v-if="field.key === 'birth_date'"
        class="form__row"
      >
        <span class="form__row-label">{{ field.label }}</span>
        <date-picker
          v-model="formValues[field.key]"
          input-class="input-date"
          valueType="format"
          placeholder="Select"
          :disabled-date="(date) => date >= new Date()"
          :class="{'is-invalid': $v.formValues[field.key] && $v.formValues[field.key].$dirty && !$v.formValues[field.key].required}"
        ></date-picker>
      </label>

      <!--Gender-->
      <template v-if="field.key === 'gender'">
        <div
          class="form__row"
          v-if="isEnableSelectGender"
        >
          <span class="form__row-label">{{ field.label }}</span>
          <label
            class="nice-radio"
          >
            <input
              type="radio"
              v-model="formValues[field.key]"
              value="Male"
            />
            <span>Male</span>
          </label>
          <label
            class="nice-radio"
          >
            <input
              type="radio"
              v-model="formValues[field.key]"
              value="Female"
            />
            <span>Female</span>
          </label>
        </div>
        <div
          class="form__row"
          v-else
        >
          <span class="form__row-label">{{ field.label }}</span>
          <label
            class="nice-radio"
          >
            <input
              type="radio"
              v-model="formValues[field.key]"
              :value="formValues[field.key]"
              :disabled="true"
            />
            <span>{{ formValues[field.key] }}</span>
          </label>
        </div>
      </template>

      <!--Email-->
      <label
        v-if="field.key === 'email'"
        class="form__row"
      >
        <span class="form__row-label">{{ field.label }}</span>
        <input
          v-model="formValues[field.key]"
          type="text"
          class="input"
          :class="{'is-invalid': $v.formValues[field.key] && $v.formValues[field.key].$dirty && (!$v.formValues[field.key].email || !$v.formValues[field.key].required)}"
          @input="resetError"
        />
      </label>
    </div>

    <p class="form__error">{{errorMessage}}</p>

    <p
      v-if="$v.formValues.$invalid && $v.formValues.$dirty"
      class="form__error"
    >
      Please complete required fields
    </p>

    <button
      class="btn btn--color-dark form__button"
      @click="saveChild"
      :disabled="isLoading"
    >
      Save
    </button>
  </div>
</template>

<script>
import { instanceSessionCamperNames } from '@core/mixins/instanceSessionCamperNames'

import axios from '@axios'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'

import { required, email } from 'vuelidate/lib/validators'
import CameraSVG from '@/assets/images/icons/icon-camera.svg'
import store from '@/store'

  export default {
    components: {
      DatePicker,
      CameraSVG,
    },
    props: {
      isShowModal: {
        type: Boolean,
        default: false,
      },
      isEnableSelectGender: {
        type: Boolean,
        default: true,
      },
      defaultGender: {
        type: String,
        default: 'Male',
      },
      familyName: {
        type: String,
        default: '',
      },
    },
    mixins: [instanceSessionCamperNames],
    data(){
      return {
        avatarPreview: null,
        formValues: {
          avatar: null,
          first_name: '',
          last_name: '',
          nickname: '',
          birth_date: '',
          gender: '',
          email: '',
        },
      }
    },
    validations() {
      const rules = {
        formValues: {
          avatar: {required: false},
          first_name: {required: false},
          last_name: {required: false},
          nickname: {required: false},
          birth_date: {required: false},
          gender: {required: false},
          email: {required: false},
        }
      }

      this.formFields.forEach(field => {
        if (field.is_required) {
          rules.formValues[field.key] = { required }
        }
      })

      if (this.formFields.some(field => field.key === 'email')) {
        rules.formValues.email = { ...rules.formValues.email, email }
      }

      return rules
    },
    watch: {
      isShowModal(val) {
        if (val) {
          this.resetModalData()
        }
      },
    },
    computed: {
      formFields() {
        return store.getters['addNewChild/getNewChildFormFields']
          .filter((field) => field.is_active)
          .map((field) => ({
            ...field,
            label: field.is_required ? `${field.label.trim()} *` : field.label,
          }));
      },
      isLoading: {
        get: function () {
          return store.getters['addNewChild/getIsLoading']
        },
        set: function (val) {
          store.commit('addNewChild/SET_IS_LOADING', val)
        }
      },
      errorMessage: {
        get: function () {
          return store.getters['addNewChild/getErrorMessage']
        },
        set: function (val) {
          store.commit('addNewChild/SET_ERROR_MESSAGE', val)
        }
      },
      serverMessage: {
        get: function () {
          return store.getters['addNewChild/getServerMessage']
        },
        set: function (val) {
          store.commit('addNewChild/SET_SERVER_MESSAGE', val)
        }
      },
    },
    async created() {
      this.isLoading = true
      await this.setNewChildForm()
      this.isLoading = false
    },
    methods: {
      async setNewChildForm() {
        await store.dispatch('addNewChild/fetchSchoolNewChildForm', {
          query: null,
        })
      },
      selectFile() {
        this.$nextTick(() => {
          this.$refs.refInputEl[0]?.click()
        })
      },
      handleFileUpload( e ){
        const file = e.target.files[0]
        const reader = new FileReader()

        reader.addEventListener(
          'load',
          () => {
            this.formValues['avatar'] = file
            this.avatarPreview = reader.result
          },
          false,
        )

        if (file) {
          reader.readAsDataURL(file)
        }
      },
      resetModalData() {
        this.avatarPreview = null

        this.formValues = {
          avatar: null,
          first_name: '',
          last_name: this.familyName,
          nickname: '',
          birth_date: '',
          gender: this.defaultGender,
          email: '',
        }
      },
      closeModal() {
        this.resetModalData()
        this.$emit('closeModal')
      },
      resetError() {
        this.errorMessage = ''
      },
      capitalizeFirstLetter(value, field){
        if (value) {
          const [firstLetter, ...rest] = value.split('')
          this[field] = firstLetter.toUpperCase() + rest.join('')
        }
      },
      async saveChild() {
        await this.$nextTick()

        await this.$v.$touch()
        if (this.$v.$invalid){
          return
        }

        const queryParams = {
          first_name: this.formValues.first_name,
          last_name: this.formValues.last_name ? this.formValues.last_name : this.familyName,
          is_student: true,
          extra_user_info: {
            gender: this.formValues.gender ? this.formValues.gender : this.defaultGender,
          }
        }
        if (this.formValues.birth_date) {
          queryParams.extra_user_info.birth_date = this.formValues.birth_date
        }
        if (this.email) {
          queryParams.email = this.email
        }
        if (this.nickname) {
          queryParams.nickname = this.formValues.nickname
        }
        if (this.avatarPreview) {
          queryParams.avatar = this.avatarPreview
        }

        this.isLoading = true
        axios
          .post('/my-children/', queryParams)
          .then(() => {
            this.$emit('updateChildrenList')
            this.isLoading = false
            this.closeModal()
          })
          .catch(error => {
            if (error.response.status === 422) {
              this.isLoading = false
              const validationErrors = Object.values(error.response.data.errors)
              this.errorMessage = validationErrors.flat()
            }
          })
        }
    }
  }
</script>

<style lang="scss" scoped>
@import 'src/assets/scss/colors/colors';

.new-child {
  position: absolute;
  border-radius: 10px;
  padding: 50px;
  width: 500px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -20%);
  background: #FFF;
  z-index: 20;
  box-shadow:  0px 0px 0px 9999px rgba(0, 0, 0, 0.5);

  &__close {
    position: absolute;
    display: flex;
    align-items: center;
    top: 20px;
    right: 20px;
    width: 15px;
    height: 15px;
    cursor: pointer;

    &::before,&::after {
      content: '';
      display: block;
      position: absolute;
      left: 6px;
      width: 1px;
      height: 15px;
      background: #000;
      transform: rotate(45deg);
    }
    &::after{
      transform: rotate(-45deg);
    }
  }
  &__title {
    font-size: 24px;
    font-weight: 700;
    text-align: center;
    margin-bottom: 30px;
  }

  &__avatar {
    display: flex;
    flex-direction: column;
    align-items: center;
    &-img {
      background: #EDEDED;
      width: 80px;
      height: 80px;
      border-radius: 50%;
      margin-bottom: 10px;
      img {
        object-fit: cover;
        width: 100%;
        height: 100%;
        border-radius: 50%;
      }
    }

    &-btn {
      cursor: pointer;
      display: flex;
      align-items: center;
      color: $primary;
      svg > g > path {
        stroke: $primary;
      }
      span {
        margin-left: 5px;
      }
    }
  }
}
@media screen and (max-width: 767px) {
  .new-child {
    position: absolute;
    border-radius: 10px;
    padding: 15px;
    width: 320px;
    left: 50%;
    top: 50%;
    background: #FFF;
    z-index: 20;
    box-shadow:  0px 0px 0px 9999px rgba(0, 0, 0, 0.5);
  }
}
</style>
<style lang="scss">
.mx-datepicker{
  &.is-invalid{
    .input-date {
      border: 1px solid var(--error) !important;
      &::placeholder {
        color: var(--error)
      }
    } 
  }  
}
.new-child__avatar-img.is-invalid {
  border: 1px solid var(--error) !important;
  border-color: var(--error);
}
</style>